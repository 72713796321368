

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/engager-services-avocat-recours-dommages-responsabilite-civile.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost28 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Poursuite en responsabilité civile engagez un avocat à Montréal pour avoir gain de cause! - Soumissions Avocat"
        description="Vous avez été victime d’un préjudice? Quelqu’un a causé un geste vous ayant occasionné des blessures et souffrances? Vous avez des droits et il n’en tient qu’à vous de les faire valoir avec l’aide d’un avocat. Soumissions Avocat vous aide en vous référant aux meilleurs avocats en responsabilité civile à Montréal!"
        image={LeadImage}>
        <h1>Poursuite en responsabilité civile engagez un avocat à Montréal pour avoir gain de cause!</h1>

					
					
						<p>Subir un préjudice, qu’il soit physique, matériel ou moral, laisse des traces parfois indélébiles sur les victimes. Quelle que soit la nature du préjudice subi, lorsqu’il résulte de la faute d’une personne, il y a lieu d’être compensé pour un tel manquement de diligence. Ce principe est d’ailleurs reconnu par le droit québécois et appuyé par ce que l’on appelle la poursuite en responsabilité civile.</p>
<p><StaticImage alt="Poursuite en responsabilité avec un avocat de Montréal" src="../images/engager-services-avocat-recours-dommages-responsabilite-civile.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Un tel recours ne vise pas à punir les personnes fautives, mais plutôt à les forcer à réparer leur geste ayant causé un tort. Cette forme de justice pourrait bien s’appliquer à votre situation si vous avez subi un dommage quelconque!</p>
<p><strong>Vérifiez donc ce qu’un avocat peut faire pour vous aider à gagner votre poursuite en responsabilité civile! </strong></p>
<h2>Quand pouvez-vous intenter un recours en responsabilité civile?</h2>
<p>Au Québec, la loi prévoit un devoir moral pour tout individu de ne pas causer préjudice à autrui dans les circonstances qui s’imposent à lui. De plus, lorsqu’il manque à un tel devoir, il est tenu de réparer ce même préjudice, quelle qu’en soit la nature. Il s’agit donc d’une façon pour la victime d’obtenir compensation et d’être restitué dans son état initial.</p>
<p>Ainsi, un recours en responsabilité civile peut être intenté dès que vous avez été victime d’une faute qui vous a causé un préjudice et que celui-ci a été causé par cette même faute.</p>
<p><strong>Quelle différence avec le recours contractuel? </strong>Le recours en responsabilité s’ouvre lorsqu’un individu vous cause un dommage en dehors d’une relation contractuelle et donc, en l’absence d’un contrat. C’est pourquoi il s’agit d’une violation de l’obligation générale de ne pas nuire à autrui!</p>
<h2>L’intérêt pour agir en justice</h2>
<p>Intenter une poursuite n’est pas aussi simple que ce que le commun des mortels semble penser. Aussi tentant puisse-t-il être de poursuivre dès que vous êtes victime d’un préjudice, le système est fait de façon à limiter les poursuites aux plus pertinentes et fondées. Vous devrez donc respecter quatre grands critères pour que votre poursuite soit admise, c’est-à-dire, qu’elle soit simplement entendue en cour. Les avocats savent reconnaître ces critères et vous aussi le serez maintenant!</p>
<p><strong>Juridique :</strong> Les disputes personnelles n’intéressent pas les tribunaux. Les juges sont chargés d’entendre des dossiers tournant autour d’un point de droit. Il faut donc que votre poursuite en responsabilité civile soit appuyée par des lois pertinentes. Comme vous n’êtes pas juristes de formation, les avocats sont les experts par excellence pour évaluer les mérites juridiques de votre cause.</p>
<p><StaticImage alt="Les critères nécessaires pour poursuivre en justice" src="../images/interet-agir-justice-responsabilite-civile-montreal.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Personnel et direct :</strong> Une poursuite, ça vise à indemniser pour le préjudice que vous avez subi personnellement. Il n’est donc pas possible de poursuivre pour le dommage qu’une autre personne a subi.</p>
<p><strong>Né :</strong> Une poursuite vise à faire valoir un droit existant, un préjudice qui a donc pris naissance au moment d’intenter le recours. Bien qu’il soit possible, dans une certaine mesure, de poursuivre pour un préjudice futur, la règle générale veut que la réparation vise un dommage déjà causé.</p>
<p><strong>
Actuel :</strong> Le qualificatif « actuel » ne signifie pas qu’on ne peut pas poursuivre pour un fait passé, mais plutôt qu’il est nécessaire de le faire avant que le recours ne devienne caduc ou que le délai de prescription n’expire. Les demandes non contemporaines n’intéressent donc pas les tribunaux.</p>
<p>Les critères mentionnés précédemment sont applicables à tous les types de poursuites civiles et non seulement les recours en responsabilité civile. Il vous faudra donc démontrer ces critères pour mener votre recours à terme et l’aide d’un avocat habitué à ce type de dossier vous sera certainement fort utile!</p>
<p><strong>Voyez plus bas les exigences particulières d’un recours en responsabilité civile!</strong></p>
<h2>Établir la présence d’une faute</h2>
<p>La première étape d’un recours en responsabilité civile consiste à établir la présence d’une faute. Un dommage causé par un acte non-fautif ne pourra pas fonder un tel recours, ce qui implique de commencer par prouver la faute d’abord et avant tout.</p>
<p><strong>Mais qu’est-ce qu’une faute au sens de la loi québécoise?</strong> On parlera d’une faute lorsqu’un comportement aura pour effet de nuire à autrui selon le standard de la personne raisonnable placée dans les mêmes circonstances.</p>
<p>On vulgarise souvent le principe en se référant au standard du « bon père de famille » afin de déterminer ce qu’une personne raisonnable fait ou omet de faire dans une situation donnée. Par ailleurs, ce seuil prévoit implicitement que la personne raisonnable, au même titre que le « bon père de famille », n’est pas parfaite et qu’il peut commettre quelques erreurs ou excès sans que cela ne constitue une faute.</p>
<p>D’ailleurs, ce même bon père de famille n’est pas devin. Il ne peut certainement pas prévoir l’issue de toute situation et être tenu responsable des dénouements dommageables. Ainsi, le droit prévoit qu’une faute ne sera commise par un individu que pour les <strong>dommages probables</strong> d’une situation donnée et non aux <strong>dommages possibles. </strong></p>
<p>Cela implique qu’en tenant compte de toutes les circonstances propres à un évènement allégué comme étant fautif, il faudra se demander si le dommage était probable ou seulement possible. La nuance se situe au niveau de degré de probabilité de l’évènement qui s’en suit.</p>
<p><StaticImage alt="Comment établir une faute civile?" src="../images/prouver-faute-responsabilite-civile-lourde-intentionnelle.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Pour illustrer un exemple, il est probable qu’il pleuve lors d’une journée nuageuse et venteuse, mais il est également possible que la foudre s’abatte sur un individu lors de la même occasion. Le premier scénario est probable, alors que le second est possible. La même logique s’applique en matière de responsabilité civile, et le bon père de famille ne sera tenu d’éviter que les dommages probables, sous peine de violer son obligation de ne pas nuire à autrui.</p>
<p>Il y a évidemment place au débat en ce qui a trait au standard de la personne raisonnable; une place qu’un avocat œuvrant dans le domaine de la responsabilité civile ne manquera pas d’occuper. Non seulement faut-il établir la présence d’une faute, encore faut-il la qualifier adéquatement. Cette qualification n’affecte pas l’engagement de la responsabilité civile de l’auteur de la faute, mais plutôt ses défenses pour s’exonérer et limiter l’octroi de dommages.</p>
<p><strong>Faute lourde :</strong> On qualifiera une faute comme lourde lorsque l’acte de l’auteur dénotera d’une insouciance grave  ou d’une négligence grossière. Il s’agit d’une situation lors de laquelle les agissements de l’auteur de la faute dénotent d’un manque de considération marqué envers les intérêts de la victime.</p>
<p>Faute intentionnelle vs. volontaire : Cette notion de faute intentionnelle ou volontaire est particulièrement pertinente en matière d’octroi de dommages. Certaines lois, dont celles régissant les indemnités d’assurances, prévoient des limitations aux dédommagements de personnes commettant des fautes intentionnelles, alors que d’autres, en matière droits et libertés, prévoient l’octroi de dommages punitifs à la victime dans un tel cas.</p>
<p>La faute <strong>volontaire</strong> est celle d’un individu causant délibérément un geste tel que frapper quelqu’un au visage suite à une dispute. En revanche, la faute <strong>intentionnelle</strong> va encore plus loin. Non seulement le geste est-il délibéré, mais il est commis dans l’intention de causer le préjudice en soi. C’est ce dernier critère se rattachant à l’intention de causer le préjudice qui distinguera la faute volontaire de celle intentionnelle.</p>
<p>Cependant, la faute intentionnelle ne s’arrête pas là. Une personne peut être reconnue coupable d’une telle faute lorsqu’elle n’agit pas dans l’intention de causer le préjudice à proprement parler, mais en agissant en pleine connaissance des effets dommageables de son geste.</p>
<p>Le principal attrait de prouver la faute intentionnelle est la possibilité d’obtenir des dommages punitifs en vertu de la Charte québécoise des droits et libertés.</p>
<p><strong>
Faute professionnelle :</strong> Il est également possible que vous soyez victime d’une faute causée par un professionnel dans le cadre de votre relation avec l’un d’eux. Qu’il s’agisse d’un notaire, d’un médecin ou d’un architecte, ce type de faute est assujetti à un standard légèrement différent. Au lieu de se référer au standard du « bon père de famille », on compare plutôt le geste posé ou la procédure effectuée aux standards de la profession elle-même, ce qui nécessite une connaissance aiguisée du domaine en question.</p>
<p><strong>Le domaine de la faute, en responsabilité civile québécoise, fait l’objet d’un débat constant sur ce qui constitue le comportement d’un « bon père de famille ». Pour prouver qu’il y a bien eu faute, c’est l’aide d’un avocat spécialisé en responsabilité civile dont vous avez besoin!</strong></p>
<h2>Démontrer l’existence d’un préjudice</h2>
<p>Une fois le geste fautif prouvé, il est temps de prouver que le préjudice que vous alléguez avoir subi en constitue bien un au sens du droit. C’est l’atteinte à un « intérêt protégé par la loi » qui constituera un préjudice susceptible d’indemnisation. Entre autres, les critères suivants aideront à faire la preuve de votre droit aux dommages.</p>
<p><strong>Préjudice certain et futur : </strong>Un préjudice ne sera reconnu que s’il est certain. Cela implique soit qu’il se manifeste au moment même d’intenter le recours ou, s’il n’a lieu que dans le futur, qu’il se concrétisera inévitablement. On exige du préjudice futur qu’il soit probable et non seulement qu’il soit possible. Le préjudice futur est principalement invoqué pour les pertes financières et les manques à gagner.</p>
<p><strong>
Préjudice par ricochet :</strong> Il est également possible de subir un préjudice dit « par ricochet » lorsque c’est l’un de vos proches qui subit la faute, mais que l’évènement vous cause également un préjudice. Pensons, par exemple, au décès d’un de vos proches vous causant des souffrances morales et psychologiques importantes.</p>
<p><strong>
Préjudice moral : </strong>Une victime peut invoquer qu’une faute lui a causé un préjudice d’ordre moral lorsqu’il sera porté atteinte à l’un de ses droits extrapatrimoniaux, tels que le droit à la liberté d’expression ou à la dignité.</p>
<p><strong>
Préjudice matériel :</strong> Résumé simplement, un préjudice sera d’ordre matériel lorsque le bien appartenant à un individu sera endommagé par le fautif allégué. Les biens immatériels sont également compris dans cette catégorie, notamment les droits de propriété intellectuelle.</p>
<p><strong>
Préjudice corporel :</strong> Finalement, une atteinte à l’intégrité corporelle d’un individu pourra également fonder un recours en responsabilité civile, indépendamment de la gravité de l’atteinte en soi.</p>
<p><strong>En ce qui concerne les trois derniers types de préjudices, il faut retenir qu’un seul d’entre eux peut fonder le recours en responsabilité, car il ne peut pas y avoir deux préjudices découlant du même geste. Ainsi, c’est l’atteinte première à l’un de ces droits qui en formera la base. </strong></p>
<h2>Prouver le lien entre la faute et le dommage</h2>
<p>Le troisième élément tout aussi crucial que la faute et le préjudice s’avèrent de prouver le lien causal entre les deux, c’est-à-dire, que la faute d’individu est bien ce qui a causé le préjudice de la victime. En absence de causalité suffisante, le recours mourra au feuilleton. Par ailleurs, il s’agit de l’aspect le plus débattu du recours en responsabilité au cœur duquel plusieurs théories de causalité suffisante s’appliquent.</p>
<p><StaticImage alt="Le lien de causalité en responsabilité civile" src="../images/prejudice-dommage-lien-causalite-responsabilite-civile-recours.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Certains adoptent le point de vue qu’une causalité dite adéquate soit à l’origine du préjudice pour établir le lien causal, signifiant que seuls les éléments étant intervenus de manière directe dans la réalisation du préjudice puissent être considérés comme déterminants. Il s’agit d’une barrière parfois haute à enjamber.</p>
<p>En revanche, d’autres préconisent une théorie plus flexible voulant que tous les facteurs ayant contribué à la réalisation de ce même préjudice puissent être invoqués et considérés comme ayant réalisé le préjudice complet. Cette position favorise évidemment la preuve adéquate du lien causal.</p>
<p>Au final, il est important de retenir que ce lien causal se prouve non pas par une preuve hors de tout doute, mais plutôt par la balance des probabilités applicable en matière civile. <strong>La zone grise retrouvée au niveau du lien causal et ce fardeau de preuve civile justifient le recours à un avocat spécialisé en responsabilité civile!</strong></p>
<h2>Est-il possible d’être exonéré d’une faute commise?</h2>
<p>Vous croyez être en voiture juste parce que vous avez prouvé la faute, le préjudice et le lien de causalité? Pas si vite! L’auteur de la faute n’est pas laissé sans défense pour autant. La loi prévoit pour cet individu des façons de limiter sa responsabilité par l’entremise des défenses reconnues par la loi et par la jurisprudence. Bien que certaines situations permettent l’exonération complète, d’autres ne feront que mitiger les dommages auxquels la victime a droit.</p>
<p><strong>Faute de la victime :</strong> Principale défense servant à mitiger la responsabilité du fautif, invoquer la faute de la victime permet d’établir que, malgré qu’il y ait bien eu faute, le comportement de la victime a eu pour effet de contribuer au préjudice subi. C’est le cas d’une personne qui agit de façon négligente, mais qui est tout de même victime de la négligence d’une autre par la même occasion. Le recours de ce premier ne sera pas complètement rejeté dans la majorité des cas, mais la responsabilité du fautif s’en verra réduite.</p>
<p><strong>
Acceptation des risques :</strong> Lorsque la victime d’un préjudice pratique une activité comportant des risques intrinsèques, l’auteur d’une faute peut s’exonérer en invoquant que la victime connaissait les risques qu’elle encourrait. Il faut évidemment que la victime agisse en connaissant réellement les risques encourus.</p>
<p>Les meilleurs exemples de la théorie de l’acceptation des risques se rattachent aux sports extrêmes tels que le Ski alpin et les sauts en parachute. Encore une fois, la victime n’est pas dépouillée de ses recours légaux, mais plus l’activité était risquée, moins ses chances sont grandes.</p>
<p>Par ailleurs, il faut toujours prouver la faute de quelqu’un dans une telle situation. Le centre de ski n’est donc pas tenu d’indemniser tous les skieurs et planchistes trop téméraires pour leurs habilités qui se retrouvent dans l’ambulance à la fin de leur journée de ski. Celui-ci doit avoir commis une faute!
<strong>
Les immunités de poursuite :</strong> Ces immunités ne s’appliquent que dans certains contextes et pour certains individus seulement. Les juges, procureurs de la couronne et le bon samaritain portant secours à autrui sont les principales personnes visées par une immunité de poursuite civile</p>
<p><strong>Les documents excluant la responsabilité civile sont-ils valides? </strong>Vous avez certainement déjà signé un document excluant la responsabilité civile d’un individu ou d’un organisme que l’on appelle communément un « waiver ». Sachez que de tels documents sont, la plupart du temps, invalides.</p>
<p>En effet, il est interdit pour un individu ou un organisme de limiter sa responsabilité civile à l’égard du préjudice moral ou corporel causé à autrui. La seule possibilité qu’un tel contrat soit valable s’applique lorsque l’exclusion vise un préjudice matériel. Ainsi, la plupart des décharges que vous avez signées au cours de votre vie ne valaient même pas le papier sur lequel ils étaient imprimés!</p>
<p><strong> </strong></p>
<h2>À quoi consiste un cas de force majeure?</h2>
<p>Une défense particulièrement puissance en droit de la responsabilité civile est celle de la force majeure. Contrairement aux autres défenses, plaider la force majeure a pour effet de faire tomber le recours de la victime du tout au tout. On décrit la force majeure de plusieurs façons, mais les juridictions anglophones la qualifient souvent comme un « act of god », en raison de son caractère inévitable.</p>
<p><strong>Elle peut donc être invoquée lorsqu’un évènement fortuit empêche l’individu fautif de remplir ses obligations légales. Cette défense doit respecter les trois critères suivants.</strong></p>
<p><strong>Irrésistible :</strong> Le fautif ne doit pas être en mesure d’éviter l’évènement fortuit afin d’invoquer avec succès la force majeure. Du moment qu’il était possible de l’éviter, la possibilité de défense tombera et le standard est haut.</p>
<p>Imprévisible : Il s’agit ici du critère le plus difficile à invoquer, car il faut prouver que le fait est imprévisible vu des circonstances. À titre d’exemple, une tempête de neige n’est pas reconnue comme un évènement imprévisible au Québec, indépendamment de ce que les prévisions indiquaient. En revanche, une tempête monstre jusqu’ici jamais vue pourrait servir de défense imprévisible.</p>
<p>Extérieur : Ce dernier critère fait référence au fait que l’acte de force majeure ne doit pas être l’œuvre de la personne fautive et ne doit pas non plus être à l’origine d’un problème interne au fautif.</p>
<h2>Le rôle des assurances en matière de responsabilité civile</h2>
<p>Avant de monter sur vos grands chevaux pour intenter un recours en responsabilité civile, sachez que des exceptions s’appliquent dans certains domaines. En effet, le monde du travail et de l’accident automobile constitue des régimes dits « sans faute » dans lesquels la victime est indemnisée en cas de préjudice, et ce, sans égard à la responsabilité de quiconque.</p>
<p><StaticImage alt="Exonération et défense de force majeure" src="../images/assurance-automobile-responsabilite-civile-montreal-sans-faute.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
Le régime « no-fault » en matière d’automobile :</strong> Si vous êtes victime d’un accident automobile vous causant des blessures, il est inutile de tenter de poursuivre l’autre automobiliste pour obtenir des dommages, et ce, même s’il est responsable de l’accident. C’est la Société de l’assurance automobile du Québec (SAAQ) qui s’occupera de vous indemniser et de payer pour les frais occasionnés par vos blessures.</p>
<p>La convention d’indemnisation directe : Les dommages matériels qu’à subi votre véhicule lors d’un accident ne peuvent pas non plus fonder un recours en responsabilité civile. C’est la Convention d’indemnisation directe qui s’applique en pareilles circonstances et chaque automobiliste doit se tourner vers son assureur afin d’être dédommagé.</p>
<p>La Loi sur les accidents de travail et maladies professionnelles. Lorsque vous êtes victime d’un accident sur vos lieux de travail, d’une blessure professionnelle ou encore d’une maladie professionnelle, la Loi prévoit que c’est la C.N.E.S.S.T, qui sera responsable de vous indemniser. Votre employeur possède donc une immunité de poursuite en cette matière.</p>
<p>L’avantage des ces régimes particuliers en matière de responsabilité civile est que plusieurs présomptions sont mises en place par la Loi sur les accidents de travail et maladies professionnelles ainsi que par la Loi sur l’assurance automobile afin de faciliter le recours des victimes.</p>
<h2>Les parents et gardiens sont-ils responsables des actes de leurs enfants?</h2>
<p>Vous vous demandez ce qui arriverait si votre progéniture commettait un acte fautif causant un préjudice à autrui. La loi prévoit différents seuils de responsabilité en fonction du type de personne qui prend soin de l’enfant. Par ailleurs, la légende urbaine voulant que les enfants ne puissent être tenus légalement responsables ou engager la responsabilité de leurs parents est complètement fausse.</p>
<p><strong>Le mineur « doué de raison ». </strong>Pour qu’un mineur puisse être tenu légalement responsable de ces gestes, il faut procéder à une analyse au cas par cas de sa capacité à « discerner le bien du mal » selon l’ancienne terminologie légale. C’est à ce moment qu’un mineur sera considéré comme étant « doué de raison » et que sa responsabilité civile pourra être engagée. Il est toutefois extrêmement rare de poursuivre un mineur en raison de l’absence d’utilité pratique; c’est plutôt vers les parents qu’on se retournera. <strong>
</strong>
<strong>La responsabilité des parents :</strong> D’abord, les parents sont responsables des gestes causés par leur enfant d’âge mineur. Cependant, les parents ont la possibilité de repousser cette même présomption en invoquant qu’ils n’ont pas commis de faute dans l’éducation, la surveillance ainsi que la garde de leur enfant. Du moment que le parent faillit à l’un de ces trois critères, sa défense tombera et sa responsabilité sera engagée.</p>
<p>La responsabilité des éducateurs : Les mêmes obligations qui s’appliquent aux parents s’appliquent aux gardiens, surveillants et éducateurs d’un enfant d’âge mineur. Cependant et contrairement aux parents, ces personnes n’auront, la plupart du temps, qu’à prouver une absence de faute dans la surveillance du mineur et non pour l’ensemble des trois critères cumulatifs applicables aux parents.</p>
<p>Le gardien bénévole : Le critère du gardien bénévole s’applique également aux gardiens et gardiennes d’enfants recevant une faible rémunération pour leurs services. La loi prévoit, dans leur cas, qu’ils ne sont pas responsables des actes des enfants mineurs à moins d’avoir commis une faute lourde ou intentionnelle dans l’exécution de leurs fonctions.</p>
<p><strong> </strong></p>
<h2>Les types de dommages que vous pouvez obtenir</h2>
<p>Le terme dommage est celui utilisé en droit pour désigner la compensation monétaire qu’une victime reçoit à l’issue d’un recours en responsabilité civile victorieux. Il existe trois grandes catégories de dommages-intérêts en droit québécois qui sert une fonction de réparation et parfois de punition. Le type de dommage auquel vous aurez droit dépendra de la nature du préjudice subi et du type de faute commise.</p>
<p><StaticImage alt="Dommages punitifs, moratoires et compensatoires" src="../images/dommages-punitifs-compensatoires-moratoires-prejudice-responsabilite-civile.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
Dommages compensatoires : </strong>Ces dommages sont ceux auxquels la victime d’un recours en responsabilité civile aura droit dans la plupart des situations, car l’idée est de remettre la victime du préjudice dans son état initial. Ce recours, tel que mentionné, ne vise pas à s’enrichir, mais bien à rétablir une situation détériorée par l’auteur de la faute.</p>
<p>Dommages moratoires : Ce type de dommages sert à réparer le préjudice subit par le retard de l’exécution d’un contrat. Par exemple, le défaut de livrer un bien à la date prévue au contrat peut causer un préjudice à la personne qui a attendu le bien en vain.</p>
<p>Dommages punitifs : Ce dernier type de dommage a une fonction simple et unique : punir le fautif pour l’acte qu’il a commis. Contrairement aux dommages compensatoires, ils ne visent pas à rétablir la situation initiale, mais bien à faire payer le fautif pour ses gestes. Contrairement à ces derniers, il faut que la loi prévoie spécifiquement leur attribution pour qu’il soit possible de les demander. De plus, une faute intentionnelle est souvent requise pour qu’il soit possible de demander des dommages punitifs.</p>
<p><strong>Un avocat habitué à ce type de recours sera en mesure de vous dire d’entrée de jeu le type de dommages auxquels vous avez le droit afin de vous obtenir une compensation juste et équitable.</strong></p>
<h2>Soumissions avocat vous aide à trouver un avocat pour votre recours en responsabilité civile à Montréal!</h2>
<p>Être la victime d’un préjudice représente un évènement grave et marquant. Les ressources d’aide étant limitées, il est normal de chercher un dédommagement là ou il est possible d’en obtenir. Si vous considérez le recours en responsabilité civile, l’aide d’un avocat spécialisé est indispensable.</p>
<p><strong>Soumissions Avocat est la référence par excellence dans la province pour vous aider. Nous sommes en mesure de vous mettre en contact avec les <ConversionLink to="/montreal/" text="meilleurs avocats à montréal" button={false}></ConversionLink> en responsabilité civile.</strong></p>
<p><strong>Il vous suffit de remplir le formulaire au bas de la page afin d’être mis en contact avec un avocat de votre région. À quoi bon attendre, cela ne vous engage en rien et la mise en contact est gratuite!</strong></p>
    </SeoPage>
)
export default BlogPost28
  